<template>
  <v-scale-transition>
    <panel
      :breadcrumbs="breadcrumbs"
      :title="title"
    >
      <v-toolbar flat>
        <v-toolbar-title>Pickup Orders</v-toolbar-title>
        <v-divider
          class="mx-2"
          inset
          vertical
        />
        <v-spacer />
        <v-text-field
          v-model="search"
          append-icon="mdi-magnify"
          label="Search"
          single-line
          hide-details
        />
      </v-toolbar>
      <v-data-table
        :headers="headers"
        :items="items"
        :loading="loading"
        :search="search"
        item-key="orderNumber"
        class="elevation-1"
        disable-initial-sort
      >
        <template #items="props">
          <tr>
            <td class="text-xs-right">
              {{ props.index + 1 }}
            </td>
            <td class="text-xs-left">
              {{ props.item.orderNumber }}
            </td>
            <td class="text-xs-left">
              {{ props.item.entry }}
            </td>
            <td class="text-xs-center">
              <v-btn
                color="primary"
                @click="openPickupDialog(props)"
              >
                Open
              </v-btn>
            </td>
          </tr>
        </template>
        <template #no-data>
          <v-btn
            color="primary"
            @click="getPickupOrder"
          >
            Load pickup orders
          </v-btn>
        </template>
      </v-data-table>

      <v-dialog
        v-model="pickupDialog"
        max-width="550px"
        persistent
      >
        <v-card>
          <v-card-title class="title font-weight-regular justify-space-between">
            <span>{{ currentTitle }}</span>
            <v-avatar
              color="primary lighten-1"
              class="subheading white--text"
              size="24"
              v-text="step"
            />
          </v-card-title>

          <v-window v-model="step">
            <v-window-item :value="1">
              <v-card-text>
                <v-alert
                  :value="true"
                  color="info"
                  icon="info"
                >
                  Please key in the <span><strong>Redemption Code</strong></span> to access order <span style="text-decoration: underline;"><strong>{{ orderNumber }}</strong></span>
                </v-alert>
                <v-form
                  ref="validateOrderForm"
                  v-model="validOrderNumber"
                  lazy-validation
                >
                  <v-text-field
                    v-model="redemptionCode"
                    label="Redemption Code"
                    :rules="[val => !!val || 'Redemption code required!']"
                    validate-on-blur
                  />
                </v-form>
              </v-card-text>
            </v-window-item>

            <v-window-item :value="2">
              <v-card-text>
                <v-alert
                  :value="true"
                  color="info"
                  icon="info"
                >
                  Please fill in the Blank SIM details to pair.
                </v-alert>
                <v-form
                  ref="validateIccidForm"
                  v-model="validIccid"
                  lazy-validation
                >
                  <v-data-table
                    :headers="detailsHeader"
                    :items="order"
                    item-key="idorder"
                    disable-initial-sort
                    hide-actions
                  >
                    <template #items="props">
                      <tr>
                        <td class="text-xs-left">
                          {{ props.item.msisdn }}
                        </td>
                        <td class="text-xs-left">
                          <v-text-field
                            v-model="props.item.iccid"
                            label="Enter Iccid"
                            counter="16"
                            :rules="[val => !!val || 'Iccid required!', val => val.length === 16 || 'Invalid Iccid!']"
                            mask="################"
                            validate-on-blur
                          />
                        </td>
                      </tr>
                    </template>
                  </v-data-table>
                  <v-container grid-list-lg>
                    <v-layout row>
                      <v-flex xs7>
                        <v-text-field
                          v-model="collectorName"
                          label="Collector name"
                          :rules="[val => !!val || 'Collector name required!']"
                          validate-on-blur
                        />
                      </v-flex>
                      <v-flex xs5>
                        <v-text-field
                          v-model="collectorNric"
                          label="Collector NRIC"
                          :rules="[val => !!val || 'Collector NRIC required!', val => val.length > 5 || 'Invalid NRIC!']"
                          mask="NNNNNNNNNNNN"
                          validate-on-blur
                        />
                      </v-flex>
                    </v-layout>
                  </v-container>
                </v-form>
              </v-card-text>
            </v-window-item>
          </v-window>

          <v-alert
            v-model="alert"
            type="error"
          >
            {{ alertMessage }}
          </v-alert>
          <v-card-actions>
            <v-btn
              :disabled="step === 1"
              v-show="step > 1"
              flat
              @click="step--"
            >
              Back
            </v-btn>
            <v-spacer />
            <v-btn
              color="blue darken-1"
              flat
              @click="closeAllDialogs(false)"
            >
              Cancel
            </v-btn>
            <v-btn
              :disabled="step === 3"
              color="primary"
              depressed
              @click="next()"
            >
              Next
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>

      <v-snackbar
        v-model="snackbar"
        color="success"
        :timeout="5000"
      >
        {{ snackbarText }}
        <v-btn
          dark
          flat
          @click="snackbar = false"
        >
          Close
        </v-btn>
      </v-snackbar>
      <v-snackbar
        v-model="snackbarError"
        color="error"
        :timeout="5000"
      >
        {{ snackbarText }}
        <v-btn
          dark
          flat
          @click="snackbarError = false"
        >
          Close
        </v-btn>
      </v-snackbar>
    </panel>
  </v-scale-transition>
</template>

<script>
import { createGetParams, createPostParams } from '@/rest'

const pageTitle = 'Simcard Pickup Order'

export default {
  components: {
    Panel: () => import(/* webpackChunkName: "Panel" */ '@/components/Panel'),
  },
  data () {
    return {
      title: pageTitle,
      items: [],
      loading: false,
      pickupDialog: false,
      redemptionCode: null,
      iccid: null,
      step: 1,
      breadcrumbs: [
        {
          text: 'Dealer Kits', disabled: false, to: '/dealer_kits',
        },
        {
          text: pageTitle, disabled: true,
        },
      ],
      detailsHeader: [
        {
          text: 'Msisdn',
          align: 'left',
          sortable: false,
          value: 'msisdn',
        },
        {
          text: 'Iccid',
          align: 'left',
          sortable: false,
          value: 'iccid',
        },
      ],
      headers: [
        {
          text: 'No',
          align: 'right',
          sortable: false,
        },
        {
          text: 'Order #',
          align: 'left',
          sortable: false,
          value: 'orderNumber',
        },
        {
          text: 'Application Date',
          align: 'left',
          sortable: false,
          value: 'entry',
        },
        {
          text: 'Action',
          align: 'center',
          sortable: false,
        },
      ],
      alert: false,
      alertMessage: null,
      collectorName: '',
      collectorNric: '',
      order: [],
      orderNumber: null,
      snackbar: false,
      snackbarError: false,
      snackbarText: null,
      validOrderNumber: true,
      validIccid: true,
      search: null,
    }
  },

  computed: {
    currentTitle: function () {
      switch (this.step) {
        case 1:
        case 2: return 'Order #: ' + this.orderNumber
        default: return 'Complete'
      }
    },
  },

  watch: {
    pickupDialog (val) {
      if (val) {
        this.$refs.validateOrderForm.resetValidation()
        this.$refs.validateIccidForm.resetValidation()
      } else {
        this.closeAllDialogs(false)
      }
    },
  },

  created: function () {
    this.getPickupOrder()
  },

  methods: {
    next () {
      if (this.step === 1) {
        if (!this.$refs.validateOrderForm.validate()) {
          return
        }

        this.order = []
        this.getPickupOrderDetails()
      } else if (this.step === 2) {
        if (!this.$refs.validateIccidForm.validate()) {
          return
        }

        this.fulfillSimcardPickup()
      }
    },

    openPickupDialog (props) {
      this.pickupDialog = true
      this.orderNumber = props.item.orderNumber
    },

    async getPickupOrderDetails () {
      try {
        const params = createGetParams({
          orderNumber: this.orderNumber,
          redemptionCode: this.redemptionCode,
        })
        const response = await this.$rest.get('getPickupOrderDetails.php', params)
        this.order = response.data
        this.step++
      } catch (error) {
        let message = error.message
        if (error.response) {
          message += ': ' + error.response.data
        }
        this.showError(message)
      }
    },

    getPickupOrder () {
      const params = createGetParams({})
      this.loading = true
      this.$rest.get('getPickupOrders.php', params)
        .then((response) => {
          this.items = response.data
          this.loading = false
        }).catch(error => {
          console.log(error)
          this.loading = false
        })
    },

    closeAllDialogs (save) {
      this.pickupDialog = false
      this.redemptionCode = null
      this.orderNumber = null
      this.order = []
      this.collectorName = ''
      this.collectorNric = ''
      this.alert = false
      this.step = 1
      setTimeout(() => {
        this.orderNumber = null
        save && this.getPickupOrder()
      }, 300)
    },

    async fulfillSimcardPickup () {
      try {
        const pairingList = []
        this.order.forEach(item => {
          pairingList.push({
            msisdn: item.msisdn,
            iccid: item.iccid,
          })
        })
        const params = createPostParams({
          action: 'fulfillSimcardPickup',
          payload: {
            orderNumber: this.orderNumber,
            redemptionCode: this.redemptionCode,
            collectorName: this.collectorName,
            collectorNric: this.collectorNric,
            pairingList,
          },
        })
        await this.$rest.post('postAction.php', params)

        this.snackbarText = 'The simcard collection has been fulfilled successfully.'
        this.snackbar = true
        this.closeAllDialogs(true)
      } catch (error) {
        let message = error.message
        if (error.response) {
          message += ': ' + error.response.data
        }
        this.showError(message)
      }
    },

    showError (message) {
      this.alertMessage = message
      this.alert = true
    },
  },
}
</script>
